import React from 'react';

const Worship = () => {
  return (
    <svg width="34" height="29" viewBox="0 0 34 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M25.5217 2.30205C25.6754 1.99076 25.7522 1.83511 25.8565 1.78538C25.9473 1.74211 26.0527 1.74211 26.1434 1.78538C26.2477 1.83511 26.3246 1.99076 26.4782 2.30205L27.936 5.25536C27.9814 5.34726 28.0041 5.39321 28.0372 5.42889C28.0666 5.46048 28.1018 5.48607 28.1409 5.50425C28.185 5.52479 28.2357 5.5322 28.3371 5.54702L31.598 6.02364C31.9414 6.07383 32.113 6.09893 32.1925 6.1828C32.2616 6.25577 32.2941 6.35603 32.281 6.45569C32.2659 6.57022 32.1416 6.69129 31.893 6.93342L29.5343 9.23078C29.4608 9.3024 29.424 9.33821 29.4003 9.38081C29.3793 9.41854 29.3658 9.45998 29.3606 9.50284C29.3547 9.55125 29.3634 9.60183 29.3807 9.703L29.9373 12.9479C29.996 13.2902 30.0253 13.4613 29.9702 13.5628C29.9222 13.6512 29.8369 13.7132 29.738 13.7315C29.6244 13.7525 29.4707 13.6717 29.1634 13.5101L26.2482 11.9771C26.1574 11.9293 26.112 11.9054 26.0641 11.896C26.0218 11.8877 25.9782 11.8877 25.9358 11.896C25.888 11.9054 25.8426 11.9293 25.7518 11.9771L22.8366 13.5101C22.5293 13.6717 22.3756 13.7525 22.262 13.7315C22.1631 13.7132 22.0778 13.6512 22.0298 13.5628C21.9746 13.4613 22.004 13.2902 22.0627 12.9479L22.6192 9.703C22.6366 9.60183 22.6453 9.55125 22.6394 9.50284C22.6342 9.45998 22.6207 9.41854 22.5997 9.38081C22.576 9.33821 22.5392 9.3024 22.4657 9.23078L20.107 6.93342C19.8584 6.69129 19.7341 6.57022 19.719 6.45569C19.7058 6.35603 19.7383 6.25577 19.8075 6.1828C19.8869 6.09893 20.0586 6.07383 20.402 6.02364L23.6629 5.54702C23.7643 5.5322 23.815 5.52479 23.8591 5.50425C23.8982 5.48607 23.9334 5.46048 23.9628 5.42889C23.9959 5.39321 24.0186 5.34726 24.064 5.25536L25.5217 2.30205Z"
        stroke="#667085"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3 25C5.33579 22.5226 8.50702 21 12 21C15.493 21 18.6642 22.5226 21 25M16.5 12.5C16.5 14.9853 14.4853 17 12 17C9.51472 17 7.5 14.9853 7.5 12.5C7.5 10.0147 9.51472 8 12 8C14.4853 8 16.5 10.0147 16.5 12.5Z"
        stroke="#667085"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default Worship;
