import React from 'react';

const Tech = () => {
  return (
    <svg width="23" height="20" viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.58334 19V12M4.58334 8V1M11.5833 19V10M11.5833 6V1M18.5833 19V14M18.5833 10V1M1.58334 12H7.58334M8.58334 6H14.5833M15.5833 14H21.5833"
        stroke="#667085"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default Tech;
