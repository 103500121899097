import React from 'react';

const Vocalist = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22.0519 1.90301C19.6944 -0.454499 15.9781 -0.614478 13.4208 1.38346C13.1011 1.10361 12.6217 1.10361 12.3418 1.42331C12.0221 1.74302 12.0221 2.22242 12.302 2.50228C11.4229 3.66094 10.9033 5.05965 10.9033 6.53806C10.9033 6.69777 10.9033 6.81791 10.9431 6.97762L2.39191 16.7276C2.11206 17.0473 2.11206 17.5267 2.43177 17.8066L3.39085 18.7654C2.95129 19.0851 2.55157 19.5247 2.19201 20.0443C1.91217 20.4838 1.6326 20.9234 1.39291 21.3629C1.23319 21.6826 1.0732 21.9622 0.873355 22.242C0.793359 22.4017 0.673503 22.5617 0.553648 22.7214C0.313937 23.081 0.393933 23.5607 0.7535 23.8403C0.913214 23.9601 1.07321 24 1.23292 24C1.47263 24 1.71234 23.8801 1.87233 23.6803C1.99218 23.5206 2.11204 23.3207 2.23189 23.1209C2.43175 22.8012 2.59146 22.4815 2.79131 22.1618C3.03102 21.7621 3.23087 21.3627 3.47057 21.0031C3.71028 20.6436 4.06984 20.2438 4.50943 19.9643L6.10765 21.5625C6.26736 21.7222 6.46722 21.8022 6.66706 21.8022C6.86691 21.8022 7.02663 21.7222 7.18661 21.6023L16.9766 13.0112C17.1363 13.0112 17.2564 13.051 17.4161 13.051C18.8546 13.051 20.2533 12.5716 21.4519 11.6523C21.6116 11.7722 21.7716 11.8522 21.9715 11.8522C22.1713 11.8522 22.3712 11.7722 22.5309 11.6125C22.8506 11.2928 22.8506 10.8134 22.5707 10.5335C24.569 7.97641 24.409 4.26037 22.0514 1.90236L22.0519 1.90301ZM6.74765 19.8446L5.429 18.5259C5.34901 18.4459 5.30915 18.3662 5.18929 18.2862L4.11033 17.2073L11.3429 8.8958C11.6626 9.73508 12.142 10.494 12.8213 11.1334C13.4607 11.7728 14.2598 12.2921 15.0589 12.6118L6.74765 19.8446ZM13.9403 10.0147C13.0213 9.09569 12.5017 7.85704 12.5017 6.53832C12.5017 5.49946 12.8214 4.50048 13.4207 3.66127L20.2937 10.5342C18.3757 11.8927 15.6584 11.7331 13.9403 10.0147V10.0147ZM21.4126 9.37555L14.5396 2.50261C15.3789 1.90334 16.4177 1.58362 17.4167 1.58362C18.6955 1.58362 19.9342 2.06304 20.893 3.02214C22.6513 4.74024 22.8113 7.4573 21.4126 9.37555H21.4126Z"
        fill="#667085"
      />
    </svg>
  );
};

export default Vocalist;
