import React from 'react';

const Organist = () => {
  return (
    <svg width="27" height="22" viewBox="0 0 27 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.611064 0.000378918C0.291138 0.000378918 0 0.291528 0 0.611443V21.3889C0 21.7089 0.291149 22 0.611064 22H26.2781C26.598 22 26.8891 21.7089 26.8891 21.3889V0.611443C26.8904 0.309638 26.6357 0.0286977 26.3354 0.000378918C26.3162 -0.000473647 7.02787 0.000378918 7.02787 0.000378918C6.70496 -0.00409704 6.40807 0.288543 6.40807 0.611443C6.40807 0.934343 6.70498 1.22698 7.02787 1.22251H25.667V7.02813H1.22224V1.22251H20.2978C20.6207 1.22698 20.9176 0.934343 20.9176 0.611443C20.9176 0.288543 20.6207 -0.0043136 20.2978 0.000378918H0.611064ZM1.22213 8.25048H3.66666V14.3617C3.66666 14.6816 3.95781 14.9727 4.27772 14.9727H5.49985V20.7784H1.22213V8.25048ZM4.88879 8.25048H7.33332V13.7505H4.88879V8.25048ZM8.55545 8.25048H11V14.3617C11 14.6816 11.2911 14.9727 11.611 14.9727H12.8332V20.7784H6.72198V14.9727H7.94411C8.26403 14.9727 8.55517 14.6816 8.55517 14.3617L8.55545 8.25048ZM12.2221 8.25048H14.6666V13.7505H12.2221V8.25048ZM15.8888 8.25048H18.3333V14.3617C18.3333 14.6816 18.6244 14.9727 18.9444 14.9727H20.1665V20.7784H14.0553V14.9727H15.2774C15.5974 14.9727 15.8885 14.6816 15.8885 14.3617L15.8888 8.25048ZM19.5554 8.25048H22V13.7505H19.5554V8.25048ZM23.2221 8.25048H25.6666V20.7784H21.3889V14.9728H22.611C22.9309 14.9728 23.2221 14.6816 23.2221 14.3617V8.25048Z"
        fill="#667085"
      />
      <circle cx="22" cy="4" r="1.5" stroke="#667085" />
      <circle cx="17" cy="4" r="1.5" stroke="#667085" />
    </svg>
  );
};

export default Organist;
